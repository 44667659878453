.error {
  color: red;
  font-size: 10px;
}

.success {
  color: green;
  font-size: 10px;
}

.inputsuccess {
  border: 1px solid green;
}

.floating-label {
  display: none;
}

.float-input:focus~.floating-label {
  display: block;
}

.float-input:not(:placeholder-shown)~.floating-label {
  display: block;
}

.float-input:focus {
  display: block;
  outline: 0.5px solid #b5cce6;
}

.float-input:focus::placeholder {
  color: transparent
}
.editable{
  padding-right: 4px;
}
.editable  .editable-btn {
  display: none;
  position: absolute;
  right:0;
  top:10;
  /* Your styles here */
}
.editable:hover  .editable-btn {
  display: inline-block;
  /* Your styles here */
}
.editable-btn:hover {
  display: inline-block;
  /* Your styles here */
}
