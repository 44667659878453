body {
  font-size: 14px !important;
  /* overflow-x: hidden !important; */
  font-family: "Nunito Sans", sans-serif, sans-serif !important;
  color: #1F2F3E !important;
  background-color: #f5f3f3 !important;
}

.css-hyum1k-MuiToolbar-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}


code {
  font-family: "Nunito Sans", sans-serif, sans-serif !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal2-container {
  z-index: 300000 !important;
}

a:hover * {
  color: 'red' !important
}

.text-ellipse {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}


.light-grey {

  color: #25324B;

}

.font-18 {

  font-size: 18px;
}

.register-color {

  background-color: #5235E8;

}

.dream-job-color {
  color: rgb(236, 7, 7);
}

.dream-job-font {
  font-size: 48px;
}

.padding-top-20 {
  padding-top: 20px;

}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-top-10p {
  padding-top: 10%;

}

.primary-blue {
  color: #5235E8;
}

.grey {
  color: #515B6F;
}

.applay-btn {
  background-color: #4640de;
  color: #fff;
  white-space: nowrap;
  padding: 12px 24px;
}

.job-description-box {
  font-size: 40px;
  background-color: #4640de;
  color: white;
}